$(function () {

    $('#registerButton').click(function() {
        console.log('Register Button clicked');
       $('.authForm').hide();
       $('#registerForm').show();
    });
    
    $('#signInButton').click(function() {
        console.log('Sign In Button clicked');
       $('.authForm').hide();
       $('#loginForm').show();
    });
    
    $('#loginForm').on('submit', function(e) {
        e.preventDefault();
        var email = $('#loginEmail').val();
        var pw1 = $('#loginPassword').val();
        if (email.trim() == '') {
            alert('Bitte eine Email angeben');
            return;
        }
        if (pw1.trim() == '') {
            alert('Bitte ein Passwort angeben');
            return;
        }
        $.ajax({
            type: "POST",
            url: apiUrl + 'auth/login',
            data: { email: email, password: pw1 },
            success: function(data) {
                if (data) {
                    if (data.status == 'success') {
                        localStorage.setItem('bearer', data.user.access_token);
                        location.reload();
                    } else {
                        if (data.message == 'invalid-credencials') {
                            $('#loginFeedbackText').html('Falsche oder unbekannte Zugangsdaten');
                            $('#loginFeedback').show();
                        }
                        if (data.message == 'email-not-confirmed') {
                            $('#loginFeedbackText').html('Deine Email-Adresse wurde noch nicht bestätigt. Bitte prüfe deine Mails und ggfs. deinen Spam Ordner.');
                            $('#loginFeedback').show();
                        }
                    }
                } else {
                    $('#loginFeedbackText').html('Leider ist etwas schief gelaufen, versuche es in ein paar Minuten nochmal');
                    $('#loginFeedback').show();
                }
            },
            error: function() {
                $('#loginFeedbackText').html('Leider ist etwas schief gelaufen, versuche es in ein paar Minuten nochmal');
                $('#loginFeedback').show();
            }
        });
    });
    
    $('#registerForm').on('submit', function(e) {
        e.preventDefault();
        var username = $('#registerUsername').val();
        var email = $('#registerEmail').val();
        var pw1 = $('#registerPassword1').val();
        var pw2 = $('#registerPassword2').val();
        if (username.trim() == '') {
            $('#signUpFeedbackText').html('Bitte gib einen Benutzernamen ein');
            $('#signUpFeedback').show();
        }
        if (username.length < 4) {
            $('#signUpFeedbackText').html('Dein Benutzername muss mindestens 4 Zeichen haben');
            $('#signUpFeedback').show();
            return;
        }
        if (username.length > 15) {
            $('#signUpFeedbackText').html('Dein Benutzername darf maximal 15 Zeichen haben');
            $('#signUpFeedback').show();
            return;
        }
        if (email.trim() == '') {
            $('#signUpFeedbackText').html('Bitte gib eine gültige Email Adresse an');
            $('#signUpFeedback').show();
            return;
        }
        if (!validateEmail(email)) {
            $('#signUpFeedbackText').html('Bitte gib eine gültige Email Adresse an');
            $('#signUpFeedback').show();
            return;
        }
        if (pw1.trim() == '') {
            $('#signUpFeedbackText').html('Du hast kein Passwort angegeben');
            $('#signUpFeedback').show();
            return;
        }
        if (pw1.length < 6) {
            $('#signUpFeedbackText').html('Dein Passwort muss mindestens 6 Zeichen haben');
            $('#signUpFeedback').show();
            return;
        }
        if (pw1.length > 60) {
            $('#signUpFeedbackText').html('Dein Passwort darf maximal 60 Zeichen haben');
            $('#signUpFeedback').show();
            return;
        }
        var regularExpression = /^(?=.*[0-9])(?=.*[!@#$%^&*()_+={}|:;"'<>,.?/\[\]\\~`\-\^])(?!.*[\s])[a-zA-Z0-9!@#$%^&*()_+={}\[\]|:;"'<>,.?/\~`\-^]{6,60}$/;
        if(!regularExpression.test(pw1)) {
            $('#signUpFeedbackText').html('Das Passwort muss mindestens eine Zahl und ein Sonderzeichen beinhalten');
            $('#signUpFeedback').show();
            return;
        }
        if (pw1 != pw2) {
            $('#signUpFeedbackText').html('Die Passwortfelder stimmen nicht überein');
            $('#signUpFeedback').show();
            return;
        }
        $.ajax({
            type: "POST",
            url: apiUrl + 'auth/signup',
            data: { username: username, email: email, password: pw1 },
            success: function(data) {
                if (data) {
                    if (data.status == 'success') {
                        $('.authForm').hide();
                        $('#loginForm').show();
                        $('#signUpSuccess').show();
                    } else {
                        if (data.message == 'account-exists') {
                            $('#signUpFeedbackText').html('Mit dieser Email Adresse existiert bereits ein Account');
                            $('#signUpFeedback').show();
                        } else {
                            $('#loginFeedbackText').html('Leider ist etwas schief gelaufen, versuche es nochmal');
                            $('#loginFeedback').show();
                        }
                    }
                } else {
                    $('#loginFeedbackText').html('Leider ist etwas schief gelaufen, versuche es nochmal');
                    $('#loginFeedback').show();
                }
            }
        });
    });
    
    const validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
     };
});